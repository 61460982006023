import * as React from "react";
import DefaultLayout from "./default";
import { Helmet } from "react-helmet";
import Container from "../components/Container";
import styled from "styled-components";
import { breakpoints, colors, dimensions, widths } from "../styles/variables";
import { useState } from "react";
import favicon from "../assets/favicon.ico";

export const Centering = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.grey};
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1048px;
`;

export const StyledModel = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  width: 220px;
  margin: 21px 21px;
  background-color: white;
`;

export const ModelImage = styled.img`
  object-fit: cover;
  height: 160px;
  width: 220px;
`;

export const ModelText = styled.div`
  padding: 14px;
`;

export const ModelTitle = styled.div`
  font-size: ${dimensions.fontSize.medium}px;
  font-weight: ${dimensions.fontWeight.regular};
`;

const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterTitle = styled.div`
  width: calc(100% / 7 * 4);
  height: 170px;
  background-color: #03498a;
  color: white;
  padding-left: 200px;
`;

const FilterTitleSmall = styled.h1`
  font-weight: ${dimensions.fontWeight.regular};
  font-size: ${dimensions.fontSize.large}px;
  margin-top: 34px;
`;

const FilterTitleLarge = styled.div`
  font-size: ${dimensions.fontSize.xl}px;
  font-weight: ${dimensions.fontWeight.bold};
  margin-top: 24px;
`;

const FilterElement = styled.div`
  width: calc(100% / 7);
  cursor: pointer;
  height: 170px;
  text-align: center;
`;

const FilterElementImg = styled.img`
  max-width: 100%;
  max-height: 165px;
`;

interface ModelProps {
  pageContext: {
    apiResponse: any;
  };
}

const Models: React.FC<ModelProps> = (props) => {
  const {
    pageContext: { apiResponse },
  } = props;

  const [filter, setFilter] = useState(0);
  let vehicleCategories = apiResponse.data.map((cat: any) => ({
    id: +cat.relationships.vehicleCategory.data.id,
    image: cat.relationships.vehicleCategory.data.attributes.image.fullsize,
    title: cat.relationships.vehicleCategory.data.attributes.title,
  }));
  vehicleCategories = vehicleCategories.filter(
    (value: any, index: any, self: any) =>
      index ===
      self.findIndex((t: any) => t.id === value.id && t.title === value.title)
  );

  const filterElements = (id: number) => {
    setFilter(id);
  };
  const filteredModels = apiResponse.data.filter(
    (value: any) =>
      value.relationships.vehicleCategory.data.id == filter || filter == 0
  );
  return (
    <DefaultLayout>
      <Helmet>
        <meta charset="utf-8" />
        <title>Fahrzeugübersicht</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <Container>
        <Filter>
          <FilterTitle>
            <FilterTitleSmall>Fahrzeugübersicht</FilterTitleSmall>
            <FilterTitleLarge>Unser Mietangebot</FilterTitleLarge>
          </FilterTitle>
          {vehicleCategories.map((cat: any, index: any) => (
            <FilterElement
              key={index}
              onClick={() => {
                filterElements(cat.id);
              }}
            >
              <FilterElementImg src={cat.image} />
            </FilterElement>
          ))}
        </Filter>
        <Centering>
          <Flex>
            {filteredModels.map((model: any, index: any) => (
              <StyledModel href={"/fahrzeug/" + model.id} key={index}>
                {model.attributes.images.length > 0 && (
                  <ModelImage
                    src={
                      model.attributes.images[0].fullsize +
                      "?auto=compress,format&fit=max&w=440&h=320"
                    }
                    alt=""
                  />
                )}
                <ModelText>
                  <ModelTitle>{model.attributes.model}</ModelTitle>
                  <br />
                  {model.relationships.vehicleCategory.data.attributes.title}
                  <br />
                  ab{" "}
                  {model.attributes.prices &&
                    model.attributes.prices[
                      model.attributes.prices.length - 1
                    ] &&
                    model.attributes.prices[model.attributes.prices.length - 1]
                      .price &&
                    model.attributes.prices[
                      model.attributes.prices.length - 1
                    ].price.toFixed(2)}{" "}
                  CHF / Tag
                </ModelText>
              </StyledModel>
            ))}
          </Flex>
        </Centering>
      </Container>
    </DefaultLayout>
  );
};

export default Models;
